
<script>
import Layout from "../../layouts/main";

import PageHeader from "@/components/page-header";
export default {
  page: {
    meta: [
      {
        name: "description"
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      products:[],

      Categories: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchCategories: null,
      CategoriesSearchMode: false,
      searchModel: "",
    };
  },
  methods: {
    addCategories() {
      var data = {
        title: "categories.add_category",
        inputs: [
          {
            "model":"name",
            "type":"text",
            "label":"popups.name_ar"
          },
          {
            "model":"name_en",
            "type":"text",
            "label":"popups.name_en"
          }
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("categories", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editCategories(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            "model":"name",
            "type":"text",
            "label":"popups.name_ar",
            "value":app.name
          },
          {
            "model":"name_en",
            "type":"text",
            "label":"popups.name_en",
            "value":app.name_en
          }
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("categories", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      if(this.searchModel.length==0){
        this.CategoriesSearchMode = false
        return this.get(this.page)
      }
      this.CategoriesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("categories/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.Categories = res.data;
          console.log("######## files", this.Categories);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.CategoriesSearchMode = false;
      this.get(this.page);
    },

    getCategories() {
      this.http.get("categories").then((res) => {
        this.Categories = res.data;
      });
    },
    deleteCategories(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("categories", app.id).then(() => {

            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("categories/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.Categories = res.data;


        });
    },
  },
  created() {
    this.get(1);
    this.getCategories();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.categories.text')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="CategoriesSearchMode"
          @click="cancelappsearchMode()"
        ></button>
        <button
          type="button"
          @click="addCategories()"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope='col'>{{$t('categories.id')}}</th>
          <th scope='col'>{{$t('categories.name')}}</th>
          <th scope='col'>{{$t('categories.name_en')}}</th>
          <th scope='col'>{{$t('categories.created')}}</th>
          <th scope='col'>{{$t('categories.operations')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, index) in Categories" :key="app" class="text-center">
          <td>{{ index+1 }}</td>
          <td>{{app.name}}</td>
          <td>{{app.name_en}}</td>

          <td>{{app.created.split('T')[0]}}</td>
          <td class="d-flex">
            <router-link :to="'/categories/' + app?.id + '/products'">
              <button
                  class="btn btn-info mx-1"
                  role="button"
                  data-toggle="tooltip"
                  data-placement="top"
                  :title="app.name + $t('popups.details')"
              >
                <i class="bx bxs-detail"></i>
              </button>
            </router-link>
            <a
              class="btn btn-primary mx-1"
              @click="editCategories(app)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.edit") }}</a
            >
            <a
              class="btn btn-danger"
              href="javascript: void(0);"
              role="button"
              @click="deleteCategories(app)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages> 1">
      <!--   Apps  paginate     -->
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




